<template>
  <div class="serveManage">
    <ds-header title="积分规则管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="积分规则名称：">
        <a-input style="width: 200px" placeholder="请输入积分规则名称" v-model="searchForm.ruleName"/>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
            style="width: 200px"
            v-model="searchForm.status"
        >
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="1">
            启用
          </a-select-option>
          <a-select-option :value="0">
            禁用
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="适用项目：">
        <a-select
            style="width: 200px"
            v-model="searchForm.projectId"
            placeholder="全部"
        >
          <a-select-option :value="item.id" v-for="item in projects" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search"/>查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <div class="upload-button-class">
        <div>
          <a-button type="primary" @click="add">
            <a-icon type="plus"/>
            新增积分规则
          </a-button>
        </div>
      </div>
    </div>
    <div style="padding-top: 15px">
      <a-table :columns="columns" :pagination="false" :data-source="data" bordered>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a @click="() => viewDetail(record.id)">查看</a>
            </span>
            <span>
              <a :disabled="record.status === 1" @click="() => edit(record.id)">编辑</a>
            </span>
            <span>
              <a v-if="record.status === 0" @click="() => enabledAndDisabled(record.id, 1)">启用</a>
              <a v-else @click="() => enabledAndDisabled(record.id, 0)">禁用</a>
            </span>
            <span>
              <a :disabled="record.status === 1" @click="() => deleteById(record)">删除</a>
            </span>
          </div>
        </template>
      </a-table>
      <div class="pagination-class">
        <a-pagination
            :show-total="total => `共 ${total} 条`"
            :page-size="pagination.pageSize"
            :default-current="pagination.current"
            :total="total"
            @change="pageChange"
            @showSizeChange="onShowSizeChange"
            show-quick-jumper
            showSizeChanger
        />
      </div>
    </div>
  </div>
</template>


<script>
import {mapState} from 'vuex';
import ConfirmBoxModule from '@/components/confirmBoxModule';
import DSTable from '@/components/DSComponents/DSTable';
import {IMG_API} from '@/config';
import { getList, toEnabledAndDisabledRule, toDeleteRuleById } from "@/api/integralRule"
import moment from 'moment'
import {
  projectName,
} from '../../../api/equityManagement';
const data = [];
export default {
  name: 'equityManagement',
  components: {
    DSTable,
    ConfirmBoxModule
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
      projects: (state) =>  state.common.projectList
    })
  },
  data() {
    return {
      moment,
      headers: {Authorization: localStorage.getItem('user').accessToken},
      // 当前处于编辑状态的数据
      nowData: {},
      // 编辑按钮是否可用
      editDisabled: false,
      data: [],
      total: 0,
      IMG_API: IMG_API,
      // 上传图片loading
      loading: false,
      searchForm: {
        ruleName: "",
        projectId: undefined,
        status: ""
      },
      pagination: {
        current: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '积分规则名称',
          dataIndex: 'ruleName',
          width: '15%'
        },
        {
          title: '适用项目',
          dataIndex: 'projects',
          customRender: (text) => {
            return text.toString()
          }
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          customRender: (text) => {
           return moment(text).format("YYYY-MM-DD HH:mm:ss")
          }
        },
        {
          title: '操作人',
          dataIndex: 'updateBy'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text)=> {
            if (text === 1) {
              return '启用'
            }  else {
              return '禁用'
            }
          }
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
        ;
  },
  created() {
    this.list()
  },
  methods: {
    // 列表
    list() {
      getList(this.searchForm, this.pagination.current, this.pagination.pageSize).then(res => {
        if (res.code === '200') {
          this.data = res.data.items;
          this.total = res.data.total;
          this.editDisabled = false;
        } else {
          this.$message.error('获取列表失败');
        }
      });
    },
    // 下载模版
    downLoadExcel() {
      let url =
          'https://oss-workplace-prod.innoecos.cn/picture_prod/056d6b5392010000_%E6%9D%83%E7%9B%8A%E7%AE%A1%E7%90%86%E6%A8%A1%E6%9D%BF.xls';
      window.open(url);
    },
    //查询
    search() {
      console.log(this.searchForm);
      this.list();
    },

    // 上传文件
    uploadFile(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`);
        this.search();
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },

    //重置
    resetSearchForm() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.searchForm.ruleName = '';
      this.searchForm.projectId = "";
      this.searchForm.status = '';
      this.list();
    },
    // 启用禁用
    enabledAndDisabled(id, status) {
      let that = this
      this.$confirm({
        title: `确定要${status === 1 ? '启用' : '禁用'}该条积分规则吗?`,
        onOk() {
          toEnabledAndDisabledRule(id, status).then(res => {
            if (res.code === '200') {
              that.$message.success("操作成功")
              that.list()
            } else {
              that.$message.error("操作失败")
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    //分页操作
    pageChange(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.list();
    },
    onShowSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.list();
    },
    // 新增
    add (){
      this.$router.push({
        path: '/integralMallManagement/integralRuleManagement/add',
        query: {
          type: 'add'
        }
      })
    },
    // 查看
    viewDetail (id){
      this.$router.push({
        path: '/integralMallManagement/integralRuleManagement/add',
        query: {
          type: 'view',
          id: id
        }
      })
    },
    // 编辑
    edit (id){
      this.$router.push({
        path: '/integralMallManagement/integralRuleManagement/add',
        query: {
          type: 'edit',
          id: id
        }
      })
    },
    // 删除
    deleteById(data) {
      let that = this
      this.$confirm({
        title: '确定要删除该条积分规则吗?',
        onOk() {
          toDeleteRuleById(data.id).then(res => {
            if (res.code === "200") {
              that.$message.success('操作成功')
              that.list();
            } else {
              that.$message.error('操作失败')
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });

    }
  }
};
</script>

<style lang="scss" scoped>
.inputClass {
  width: 200px;
}

.upload-button-class {
  display: flex;
  margin-bottom: 10px;

  & > div {
    padding-right: 15px;
  }
}

.editable-row-operations a {
  margin-right: 8px;
}

.pagination-class {
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

</style>
